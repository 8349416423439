/*
** Copyright (C) Eurekative Pty Ltd - All Rights Reserved
**
** Unauthorised copying of this file, via any medium is strictly prohibited.
** Proprietary and confidential
**
** Author(s):
**  - Ian Firns <ian@eurekative.com.au>
*/
import './scss/vsqt.scss';
import formtmpl from './vsqt.tmpl';
import help from './svgs/question-circle-solid.svg';
import warning from './svgs/exclamation-triangle-solid.svg';
import chevronup from './svgs/chevron-up-solid.svg';
import chevrondown from './svgs/chevron-down-solid.svg';
import logo from './svgs/vs-logo.svg';

(function() {
  'use strict';

  const angular = require('angular');
  const marked = require('marked');

  var ID;

  window.startVSQT = function(id, anchor) {
    if (!id) {
      console.error('No ID specified');
    }

    ID = id;

    var templateDiv = document.createElement("div");
    templateDiv.style.zIndex = 99999999;
    templateDiv.innerHTML = formtmpl;
    document.body.appendChild(templateDiv);

    var anchorElement = document.getElementById(anchor);
    var dismissElement = document.getElementById('#vsqt-dismiss');

    if (anchorElement && dismissElement) {
      var modalElement = document.getElementsByClassName('vsqt-modal')[0];
      var modalShade = document.createElement('div')
      modalShade.classList.add('vsqt-modal-backdrop', 'vsqt-fade');

      // attach open handler to anchor
      anchorElement.addEventListener('click', function() {
        document.body.classList.add('vsqt-modal-open');
        modalElement.setAttribute('style', 'display: block;');
        modalElement.removeAttribute('aria-hidden');
        setTimeout(function() {modalElement.classList.add('vsqt-show');}, 0);

        // add shade
        modalElement.parentNode.insertBefore(modalShade, modalElement.nextSibling);
        setTimeout(function() {modalShade.classList.add('vsqt-show');}, 0);
      });

      // attach close handler to close
      dismissElement.addEventListener('click', function() {
        document.body.classList.remove('vsqt-modal-open');
        modalElement.classList.remove('vsqt-show');
        modalElement.setAttribute('style', 'display: none;');
        modalElement.setAttribute('aria-hidden', true);

        // remove shade
        modalShade.parentNode.removeChild(modalShade);
        modalShade.classList.remove('vsqt-show');
      });
    } else {
      if (!anchorElement) {
        console.error('Unable to find anchor: ' + anchor);
      }

      if (!dismissElement) {
        console.error('Unable to find dismiss anchor.');
      }
    }

    buildApp();
  }

  function buildApp() {
    angular.module('vsqt', []);
    var app = angular.module('vsqt');

    app.controller('QuoteCtrl', ['$http', '$q', '$scope', '$timeout', '$sce', '$location', '$anchorScroll', function($http, $q, $scope, $timeout, $sce, $location, $anchorScroll) {
      var debounceQuote;

      // model
      var vm = angular.extend(this, {
        state: {
          errors: [],
          hasSavedQuote: false,
          mode: 'login',
          building: false,
          built: false,
          quoting: false,
          validating: false,
        },
        email: '',
        pin: '',
        extrainfo: '',
        noPin: false,
        activeStep: 0,
        answers: {},
        quote: {},
        form: {
          steps: [],
        },
        icons: {
          warning: warning,
          help: help,
          logo: logo,
        },
      });

      // methods
      angular.extend(vm, {
        canStartOver: canStartOver,
        canStepNext: canStepNext,
        canStepPrev: canStepPrev,
        formatLoan: formatLoan,
        formatNext: formatNext,
        formatPrev: formatPrev,
        formatPrice: formatPrice,
        formatRebates: formatRebates,
        formatRegisterPreamble: formatRegisterPreamble,
        formatSize: formatSize,
        formatUpFront: formatUpFront,
        getErrors: getErrors,
        getMessages: getMessages,
        getVendorLogoUrl: getVendorLogoUrl,
        hasMessages: hasMessages,
        withLoan: withLoan,
        hasQuote: hasQuote,
        hasStepNext: hasStepNext,
        isGreen: isGreen,
        isState: isState,
        isQuoteBattery: isQuoteBattery,
        quoteClass: quoteClass,
        onValidityChange: onValidityChange,
        startOver: startOver,
        stepNext: stepNext,
        stepPrev: stepPrev,
        pinKeyup: pinKeyup,
      });

      // watchers
      $scope.$watch(_watchAnswers, _onAnswerUpdate, true);

      _activate();

      ////

      function canStartOver() {
        return !(isState('login') || isState('register-forgot') || isState('error'));
      }

      function canStepNext() {
        if (isState('login')) {
          return (
            !!vm.email && vm.email.trim().length > 0 &&
            (
              !vm.noPin && !!vm.pin && vm.pin.trim().length === 4 ||
              vm.noPin
            )
          );
        } else if (isState('register-forgot')) {
          return true;
        } else if (isState('registered')) {
          return vm.state.validity && vm.activeStep < vm.form.steps.length;
        } else if (isState('review')) {
          return true;
        } else if (isState('error')) {
          return true;
        }

        return false;
      }

      function canStepPrev() {
        if (vm.activeStep + 1 === vm.form.steps.length) {
          return false;
        }

        if (isState('review')) {
          return true;
        }

        if (isState('registered')) {
          return true;
        }

        return false;
      }

      function formatLoan() {
        var loan = vm.quote[vm.quote.userrequested].loanamount || 0;

        return loan === 0 ? '$0' : '-$' + loan;
      }

      function formatNext() {
        if (isState('registered') && vm.activeStep + 1 === vm.form.steps.length) {
          return 'Review';
        } else if (isState('review')) {
          return 'Send';
        } else if (isState('error')) {
          return 'Try Again';
        }

        return 'Next';
      }

      function formatPrev() {
        if (isState('review')) {
          return 'Modify';
        }

        return 'Review';
      }

      function formatPrice() {
        var price = vm.quote[vm.quote.userrequested].systemprice || 0;

        return '$' + price;
      }

      function formatRebates() {
        var rebate = vm.quote[vm.quote.userrequested].rebatesincentives || 0;

        return rebate === 0 ? '$0' : '-$' + rebate;
      }

      function formatRegisterPreamble() {
        var preamble = vm.form.preamble || '';
        return marked(preamble);
      }

      function formatSize() {
        return vm.quote[vm.quote.userrequested].systemsize || 0;
      }

      function formatUpFront() {
        var upfront = vm.quote[vm.quote.userrequested].upfront || 0;

        return '$' + upfront;
      }

      function getErrors() {
        return vm.state.errors;
      }

      function getMessages() {
        if (
          vm.quote.hasOwnProperty("userrequested") &&
          vm.quote[vm.quote.userrequested]
        ) {
          return vm.quote[vm.quote.userrequested].messages || [];
        }
        return [];
      }

      function getVendorLogoUrl() {
        return vm.form.vendorLogo;
      }

      function hasMessages() {
        return (
          hasQuote() &&
          vm.quote.hasOwnProperty("userrequested") &&
          vm.quote[vm.quote.userrequested] &&
          vm.quote[vm.quote.userrequested].messages.length > 0
        );
      }

      function withLoan() {
        return vm.quote[vm.quote.userrequested].loanamount > 0;
      }

      function hasQuote() {
        return !angular.equals(vm.quote, {});
      }

      function hasStepNext() {
        if (isState('complete')) {
          return false;
        }

        return true;
      }

      function hasVendorLogo() {
        return !!vm.form.vendorLogo;
      }

      function isGreen() {
        return vm.answers.environmental;
      }

      function isQuoteBattery() {
        return vm.quote.userrequested === 'withbattery';
      }

      function quoteClass(battery) {
        if (battery && (vm.quote.userrequested === 'withbattery')) {
          return "active";
        }
        else if (!battery && (vm.quote.userrequested === 'withoutbattery')) {
          return "active";
        }
        else {
          return "comparison";
        }
      }

      function isState(state) {
        return state === vm.state.mode;
      }

      function onValidityChange(state) {
        vm.state.validity = state;
      }

      function startOver() {
        vm.answers = angular.merge({}, vm.defaultAnswers);
        vm.state.mode = 'registered';
      }

      function stepNext() {
        if (isState('login')) {
          if (vm.noPin) {
            _registerForgotPin()
              .then(function() {
                vm.state.mode = 'register-forgot';
              }, function() {
                vm.state.mode = 'error';
                vm.state.errors = [
                  'Unable to register email.',
                  'Please try again.'
                ];
              });
          } else {
            _validateEmailPin()
              .then(function() {
                // trigger a quote check
                _fetchQuote()
                  .then(function() {
                    if (vm.state.hasSavedQuote) {
                      vm.state.mode = 'review';
                    } else {
                      vm.state.mode = 'registered';
                      vm.activeStep = 0;
                    }
                  }, function() {
                    return $q.reject();
                  });
              }, function() {
                vm.state.mode = 'error';
                vm.state.errors = [
                  'We were unable to validate your email and pin combination.',
                  'Please try again. If it continues, re-register by selecting you don\'t have a pin.'
                ];
              });
          }
        } else if (isState('register-forgot')) {
          vm.state.mode = 'login';
          vm.noPin = false;
        } else if (isState('registered')) {
          if (vm.activeStep + 1 < vm.form.steps.length) {
            vm.activeStep++;
          } else if  (vm.activeStep + 1 === vm.form.steps.length) {
            vm.state.mode = 'review';
          }
        } else if (isState('review')) {
          // TODO:
          // - send quote
          _sendQuote()
              .then(function() {
                vm.state.mode = 'complete';
              }, function() {
                vm.state.mode = 'error';
                vm.state.errors = [
                  'Unable to email your quote.',
                  'Please try again.'
                ];
              });
        } else if (isState('error')) {
          vm.state.mode = 'login';
          vm.state.errors = [];
          vm.noPin = false;
          vm.pin = '';
        }
      }

      function stepPrev() {
        if (isState('review')) {
          vm.state.mode = 'registered';
          vm.activeStep = 0;
        }
        else if (isState('registered')) {
          vm.state.mode = 'review';
        }
      }

      function pinKeyup() {
        if (vm.pin.length == 4 && vm.canStepNext()) {
          vm.stepNext();
        }
      }

      /*
      ** PRIVATE
      */

      function _activate() {
        _fetchLayout()
          .then(function() {
            vm.state.built = true;
            vm.email = sessionStorage.getItem('vsqt-email') || '';
          }, function() {
            console.error('Unable to activate!');
          });
      }

      function _fetchLayout() {
        vm.state.building = true;

        return $http({
          method: 'GET',
          url: API + '/form/' + ID,
        })
          .then(function(res) {
            vm.form = {
              vendorLogo: null,
              preamble: `Welcome to our brand new automated quote estimator. It's still undergoing some development so if you hit an error or have difficulty using it please contact us at info@velocitysolar.com.au. We'd also to love any feedback you may have with the system!`,
              steps: res.data
            };

            vm.activeStep = 0;

            // build default answer set
            var data = {};
            vm.form.steps.forEach(function(s) {
              s.questions.forEach(function(q) {
                if (q.type === 'check') {
                  data[q.key] = false;
                } else if (q.type === 'option') {
                  data[q.key] = q.hasOwnProperty('default') ? q.default : q.options[0].value;
                }
              })
            });

            vm.defaultAnswers = data;
          }, function(err) {
            console.error(err);
          })
          .finally(function() {
            vm.state.building = false;
          });
      }

      function _fetchQuote() {
        if (vm.state.built && !vm.state.quoting) {
          vm.state.quoting = true;

          return $http({
            method: 'PUT',
            url: API + '/quote/' + vm.email,
            data: angular.merge({id: ID, pin: vm.pin}, vm.answers)
          })
            .then(function(res) {
              vm.quote = res.data;
            }, function(err) {
              return $q.reject(err);
            }).
            finally(function() {
              vm.state.quoting = false;
            });
        }

        return $q.reject();
      }

      function _onAnswerUpdate(n, o) {
        if (['registered'].indexOf(vm.state.mode) !== -1 && n !== {}) {
          $timeout.cancel(debounceQuote);
          debounceQuote = $timeout(function() {
            _fetchQuote()
              .catch(angular.noop);
          }, 250);
        }
      }

      function _sendQuote() {
        return $http({
          method: 'POST',
          url: API + '/quote/' + vm.email,
          data: {
            pin: vm.pin,
            id: ID,
            extrainfo: vm.extrainfo
          },
        });
      }

      function _registerForgotPin() {
        vm.state.validating = true;

        return $http({
          method: 'POST',
          url: API + '/quote',
          data: {
            email: vm.email,
            id: ID
          },
        })
          .finally(function() {
            vm.state.validating = false;
          });
      }

      function _validateEmailPin() {
        vm.state.validating = true;

        return $http({
          method: 'POST',
          url: API + '/quote',
          data: {
            email: vm.email,
            pin: vm.pin,
            id: ID
          },
        })
          .then(function(res) {
            sessionStorage.setItem('vsqt-email', vm.email);

            vm.state.hasSavedQuote = !angular.equals(res.data.answers, {});
            vm.answers = angular.merge({}, vm.defaultAnswers, res.data.answers);
          }, function(err) {
            if (err.data.message !== 'email or pin incorrect') {
              console.error(err);
            }

            return $q.reject(err);
          }).
          finally(function() {
            vm.state.validating = false;
          });
      }

      function _watchAnswers() {
        return vm.answers;
      }
    }]);

    app.filter("trust", ['$sce', function($sce) {
      return function(htmlCode){
        return $sce.trustAsHtml(htmlCode);
      }
    }]);

    app.directive('stepTabs', [function() {
      return {
        restrict: 'E',
        replace: true,
        template: '<ul class="nav nav-pills nav-fill">' +
          '<li class="nav-item" ng-repeat="step in steps">' +
            '<a href="#" class="nav-link" ng-class="{active: isActive($index)}" ng-click="onSelect($index)">{{formatTab($index)}}</a>' +
          '</li>' +
        '</ul>',
        scope: {
          activeStep: '=',
          steps: '<',
        },
        link: function(scope, elem, attrs) {
          scope.formatTab = function(tab) {
            return scope.steps[tab].tab || '???';
          }

          scope.isActive = function(tab) {
            return scope.activeStep === tab;
          }

          scope.onSelect = function(tab) {
            scope.activeStep = tab;
          }
        }
      };
    }]);

    app.directive('stepQuestions', [function() {
      return {
        restrict: 'E',
        replace: true,
        template: '<div class="vsqt-steps">' +
          '<h3 class="vsqt-subtitle">{{formatHeading()}}</h3>' +
          '<form name="questions">' +
            '<div ng-repeat="question in getQuestions()" class="mb-3">' +
              '<div ng-if="isType(question, \'option\')">' +
                '<p class="mb-1">{{question.text}}' +
                  '<span scroll-to-help ng-click="expandHelp()" ng-if="question.hint""><img class="vsqt-icon svg-inline--fa fa-w-14" ng-src="{{helpImg}}" title="{{question.hint}}"></span>' +
                '</p>' +
                '<div class="vsqt-answers">' +
                  '<vsqt-question class="vsqt-question" ng-repeat="o in question.options" model="answers[question.key]" value="{{o.value}}" label="{{o.text}}"></vsqt-question>' +
                '</div>' +
              '</div>' +
              '<div ng-if="isType(question, \'check\')">' +
                '<p class="mb-1">{{question.text}}' +
                  '<span scroll-to-help ng-click="expandHelp()" ng-if="question.hint""><img class="vsqt-icon svg-inline--fa fa-w-14" ng-src="{{helpImg}}" title="{{question.hint}}"></span>' +
                '</p>' +
                '<div class="vsqt-answers">' +
                  '<vsqt-question class="vsqt-question" ng-repeat="o in checkOptions" model="answers[question.key]" value="{{o.value}}" label="{{o.text}}"></vsqt-question>' +
                '</div>' +
              '</div>' +
            '</div>' +
          '</form>' +
          '<div id="help" class="vsqt-sectionhelp" ng-if="getHelp()">' +
            '<a href="#" ng-click="toggleHelp()">' +
              'More Information' +
              '<img class="vsqt-icon svg-inline--fa fa-w-28" ng-src="{{chevronDownImg}}" ng-if="showHelp">' +
              '<img class="vsqt-icon svg-inline--fa fa-w-28" ng-src="{{chevronUpImg}}" ng-if="!showHelp">' +
            '</a>' +
            '<div class="vsqt-help" ng-if="showHelp" ng-bind-html="getHelp() | trust"></div>' +
          '</div>' +
        '</div>',
        scope: {
          activeStep: '<',
          steps: '<',
          answers: '=',
          validityChange: '&',
        },
        link: function(scope, elem, attrs) {
          scope.checkOptions = [
            { value: true, text: 'Yes' },
            { value: false, text: 'No' }
          ];

          scope.showHelp = false;
          scope.helpImg = help;
          scope.chevronUpImg = chevronup;
          scope.chevronDownImg = chevrondown;

          scope.form = elem.find('form').eq(0).controller('form');

          scope.toggleHelp = function() {
            scope.showHelp = !scope.showHelp;
            scope.helpIcon = scope.showHelp ? "-" : "+";
          };

          scope.expandHelp = function() {
            scope.showHelp = true;
            scope.helpIcon = scope.showHelp ? "-" : "+";
          };

          scope.getHelp = function() {
            var s = scope.activeStep;
            if (scope.steps[s].hasOwnProperty('help')) {
              return marked(scope.steps[s].help);
            }
            else {
              return '';
            }
          };

          scope.formatHeading = function() {
            var s = scope.activeStep;
            return scope.steps[s].heading || scope.steps[s].tab;
          };

          scope.getQuestions = function() {
            return scope.steps[scope.activeStep].questions || [];
          };

          scope.isType = function(question, type) {
            return question.type === type;
          };

          scope.$watch(
            function() { return scope.form.$valid; },
            function(n, o) { scope.validityChange({state: n}); }
          );
        }
      };
    }]);

    app.directive('vsqtEnter', function() {
      return function(scope, elem, attrs) {
        elem.bind("keydown keypress", function(event) {
          if(event.which === 13) {
            scope.$apply(function() {scope.$eval(attrs.vsqtEnter);});
            event.preventDefault();
          }
        });
      };
    });

    app.directive('vsqtQuestion', function() {
      return {
        restrict: 'E',
        scope   : {
          model: '=',
          value: '@',
          label: '@'
        },
        template:
        '<label class="vsqt-radio" ng-class="{active: model.toString()===value.toString()}" ng-model="model">' +
          '<input type="radio" name="{{model}}" ng-model="model" ng-value="value" ng-checked="model===value">{{label}}' +
        '</label>'
      };
    });

    app.directive('scrollToHelp', ['$location', '$anchorScroll', function($location, $anchorScroll) {
      return {
        restrict: 'A',
        link: function(scope, $elm, attr) {
          $elm.on('click', function() {
            $location.hash('help');
            $anchorScroll();
          });
        }
      };
    }]);

    // templates
    app.run(["$templateCache", function($templateCache) {
      $templateCache.put("vsqt/tool.tpl.html","<ul></ul>");
    }]);

    // bootstrap
    angular.element(document).ready(function() {
      angular.bootstrap(document, ["vsqt"]);
    });
  }
})();
